<template lang="pug">
  animate-push-on-panel.slice-body-text.my-70.text-14.md_text-16.xl_text-18.md_px-20.flex.flex-wrap.justify-start.items-start.bold-is-teal
    //- details (left)
    .w-full.md_w-3x12.px-20.textbody(v-html="slice.details || ''")
    //- main text
    .w-full.px-20.md_w-6x12.textbody(v-html="slice.text")
</template>

<script>
export default {
  name: 'SliceBodyText',
  props: {
    slice: Object
  }
}
</script>

<style>
.slice-body-text{
  & .paragraph-lg,
  & .blockquote-lg{
    font-size: calc(24/18 * 1em);
  }

  & blockquote {
    font-size: calc(14/18 * 1em);
    @apply pl-20;
  }

  & .blockquote-lg {
    @apply my-50 pl-0;
  }

  & cite:before{
    content: '– ';
  }

  & sup{
    @apply text-teal;
  }

  & h3 {
    @apply text-22 mb-30
  }
  & table {
    @apply w-full;
    & td{
      @apply border p-10 border-current;
      vertical-align: top;
      font-size: 0.77em;
      & * + * {
        @apply mt-lh
      }
    }
  }

  & .footnote-hover{
    /* appears as half-screen overlay */
    @apply absolute right-0 w-1x2 bg-white text-teal text-10 pl-20 pr-20 pt-10 pb-20 transform -translate-y-full;
    & sup {
      @apply absolute top-auto;
      left: 0.5rem;
      line-height: normal;
      font-size: inherit;
    }
  }

  & .boxlink-in-sidebar{
    @apply flex w-full justify-end text-12 my-25;
    & > * {
      @apply h-30 px-15 border border-current flex items-center justify-center whitespace-no-wrap overflow-hidden transform -translate-y-full;
      box-shadow: none;
    }
  }
}

@media (min-width:900px) {
  .slice-body-text {
    & .blockquote-lg{
      @apply transform translate-x-2x3 w-3x4 my-70
    }

    & h3 {
      @apply text-24 mb-40
    }

    /*footnote hover*/
    & .footnote-hover{
      @apply w-3x12vw pr-40 pl-30 py-0 mt-0 transform -translate-y-full;
      & sup {
        left: 1rem;
      }
    }

    & .boxlink-in-sidebar{
      position: absolute;
      width:100vw;
      right:0;
      @apply my-0 px-40 justify-end;
      & > * {
        width: calc((100% + 4rem) * 2/12 - 4rem);
      }
    }
  }
}

/*@media (min-width:1140px) {
  .slice-body-text{
    & .footnote-hover{
      @apply w-2x12vw;
    }
  }
}*/

/* TODO use bkpt variables... */
@media (min-width:1281px) {
  .slice-body-text {
    & .boxlink-in-sidebar{
      @apply h-45 text-18;
      & > * {
        @apply h-45;
      }
    }
  }
}

/*@media (hover:hover) {*/
  .slice-body-text{

    & .footnote-hover{
      @apply opacity-0 transition-opacity duration-200 delay-200;
    }
    & *:hover + .footnote-hover,
    & .footnote-hover:hover {
      @apply delay-0;
      opacity:1;
    }
  }
/*}*/
</style>
