<template lang="pug">
  .slice-scroll-point.pt-px.-mt-px(:id="kb(slice.heading)")
    h2.sr-only {{ slice.heading }}
</template>

<script>
import kb from 'lodash/kebabCase'
export default {
  name: 'SliceScrollPoint',
  props: {
    slice: Object,
    index: Number
  },
  methods: {
    kb
  }
}
</script>

<style>
</style>
