<template lang="pug">
  section.slice-typographic-about.bg-teal.min-h-screen(:class="{'-mt-90 pt-90': isTopSlice}")
    .pt-40.md_pt-20.underline-links
      .relative.text-22.md_text-30.xl_text-34.px-20
        //- first text
        .md_w-9x12.ml-auto.md_px-20.pb-90.md_pb-120
          p The Roberts Institute of Art <span class="children-inline" v-html="slice.paragraph0"></span>

        .md_w-9x12.ml-auto.md_px-20.pb-90.md_pb-120
          //- second text
          h3.mb-50.md_mb-60.relative.opacity-0.transition.duration-700.text-18.md_text-20.xl_text-24
            | Roberts
            observer.absolute.top-0.left-0.w-px.h-40vh(:threshold="0.99", @visible="revealParent")

          .md_w-2x3.relative.transition.duration-700.opacity-0
            //- | We reflect our Founders’ social and open-minded approach in how we programme, communicate and collaborate.
            div(v-html="slice.paragraph1")
            observer.absolute.top-0.left-0.w-px.h-40vh(:threshold="0.99", @visible="revealParent")

        .md_w-8x12.ml-auto.md_px-20.pb-90.md_pb-120
          h3.mb-50.md_mb-60.relative.opacity-0.transition.duration-700.text-18.md_text-20.xl_text-24
            | Institute
            observer.absolute.top-0.left-0.w-px.h-40vh(:threshold="0.99", @visible="revealParent")

          .relative.transition.duration-700.opacity-0
            //- | The Institute is a shared endeavour developed around partnership and co-creation. We build on the tradition of an arts institute through a reflective and questioning lens. We are a site of exchange for artists, curators, collaborators and audiences where relationships are explored and reimagined.
            div(v-html="slice.paragraph2")
            observer.absolute.top-0.left-0.w-px.h-40vh(:threshold="0.99", @visible="revealParent")

        .md_w-7x12.ml-auto.md_px-20.pb-90.md_pb-150
          h3.mb-50.md_mb-60.relative.opacity-0.transition.duration-700.text-18.md_text-20.xl_text-24
            | Art
            observer.absolute.top-0.left-0.w-px.h-40vh(:threshold="0.99", @visible="revealParent")

          .relative.transition.duration-700.opacity-0
            //- | We champion an expansive vision of art from the diversity and breadth of the David and Indrė Roberts Collection to our live events which celebrate the crossovers between art, performance, music and dance.
            div(v-html="slice.paragraph3")
            observer.absolute.top-0.left-0.w-px.h-40vh(:threshold="0.99", @visible="revealParent")

</template>

<script>
export default {
  name: 'SliceTypographicAbout',
  props: {
    slice: Object,
    index: Number
  },
  data () {
    return {
      first: false,
      second: false,
      third: false
    }
  },
  computed: {
    isTopSlice () {
      return this.index < 2
    }
  },
  methods: {
    revealParent (el) {
      el.parentNode.classList.remove('opacity-0')
      // el.remove()
    }
  }
}
</script>

<style>
</style>
